<template>
  <div class="mt-5">
    <block-dashboard-wrapper title="Liste des actualités">
      <template v-slot:button>
        <button
          type="button"
          class="content-wrapper__header-button"
          @click="createArticle()"
        >
          Ajouter une actualité
        </button>
      </template>
      <block-news-table @updateNew="editArticle($event)"></block-news-table>
    </block-dashboard-wrapper>
    <molecule-modal-wrapper :open="isModalOpen" @modalClose="closeModal()">
      <block-news-form :article="modalArticle" @modalClose="closeModal()"></block-news-form>
    </molecule-modal-wrapper>
  </div>
</template>

<script setup>
import BlockDashboardWrapper from "@/components/blocks/BlockDashboardWrapper.vue";
import BlockNewsTable from "@/components/blocks/tables/BlockNewsTable.vue";
import MoleculeModalWrapper from "@/components/molecules/MoleculeModalWrapper.vue";
import BlockNewsForm from "@/components/blocks/forms/BlockNewsForm.vue";
import { ref } from "vue";


// Modal code
const isModalOpen = ref(false);
const isEditMode = ref(false);
const closeModal = () => {
  isModalOpen.value = false;
};
// Modal user code
const modalArticle = ref({});
// Create user function
const createArticle = () => {
  isModalOpen.value = true;
  modalArticle.value = {};
};
// Edit user function
const editArticle = (article) => {
  modalArticle.value = article;
  isModalOpen.value = true;
};
</script>

<style lang="scss" scoped></style>
